<template>
  <div>
    <div class="das_top_cont">
      <div class="history_box">
        <div class="accountright_cont_box">
          <div class="flex_center_between_box" style="">
            <h2 class="history_box-head">Invoice Information</h2>

            <div style="display: flex">
              <button class="btn_blue flex_center btn_eee change_btn" v-if="Edit" @click="Edit = !Edit">
                Edit
              </button>
              <div v-else style="display: flex">
                <button class="btn_blue flex_center change_btn_mr10" @click="SubmitFrom('ruleForm')" >
                  Save
                </button>
                <button class="btn_blue flex_center change_btn_gray" @click="Edit = !Edit,getInvoiceInformationList()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div class="his_sel_box" style="width: 100%">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
            
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="CompanyName">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.CompanyName"
                      placeholder="Company Name">
                      <span slot="prefix" v-if="!ruleForm.CompanyName" class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item label="" prop="PhoneNumber">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.PhoneNumber"
                      placeholder="+1 Phone Number">
                      <span slot="prefix" v-if="!ruleForm.PhoneNumber" class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
           
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item >
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.CompanyID"
                      placeholder="CompanyID">
                      <!-- <span slot="prefix" class="prefixRed">*</span> -->
                    </el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="Email">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.Email"
                      placeholder="Email">
                      <span slot="prefix"  v-if="!ruleForm.Email"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="Region">
                    <el-input  :disabled="Edit" class="inline-input" clearable v-model="ruleForm.Region"
                      placeholder="Country/Region">
                      <span slot="prefix"  v-if="!ruleForm.Region"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="BankAccountHolder">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.BankAccountHolder"
                      placeholder="Bank Account Holder Name">
                      <span slot="prefix"  v-if="!ruleForm.BankAccountHolder"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="AddressLine">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.AddressLine"
                      placeholder="Address Line 1">
                      <span slot="prefix"  v-if="!ruleForm.AddressLine"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="BankName">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.BankName"
                      placeholder="Beneficiary Bank Name">
                      <span slot="prefix"  v-if="!ruleForm.BankName"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="AddressLine2">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.AddressLine2"
                      placeholder="Address Line 2">
                      <span slot="prefix"  v-if="!ruleForm.AddressLine2"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="CODE">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.CODE"
                      placeholder="SWIFT CODE(BIC)">
                      <span slot="prefix" v-if="!ruleForm.CODE"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content font_size_12">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="City">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.City"
                      placeholder="City">
                      <span slot="prefix" v-if="!ruleForm.City"   class="prefixRed">*</span>
                    </el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="AccountNumber">
                    <el-input class="inline-input" :disabled="Edit" clearable v-model="ruleForm.AccountNumber"
                      placeholder="Account Number">
                      <span slot="prefix" v-if="!ruleForm.AccountNumber"   class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="ZipCode">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.ZipCode"
                      placeholder="Zip Code">
                      <span slot="prefix" v-if="!ruleForm.ZipCode"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="BeneficiaryBankAddress">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.BeneficiaryBankAddress"
                      placeholder="Beneficiary Bank Address">
                      <span slot="prefix"  v-if="!ruleForm.BeneficiaryBankAddress"   class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="FirstName">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.FirstName"
                    placeholder="First Namer">
                    <span slot="prefix"  v-if="!ruleForm.FirstName"   class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item  prop="ABA">
                    <el-input class="inline-input" :disabled="Edit" clearable v-model="ruleForm.ABA"
                      placeholder="ABA(Routing Number)">
                      <span slot="prefix" v-if="!ruleForm.ABA"  class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="information-content">
                  <div class="sel_box computer_right_cont_box">
                    <el-form-item prop="LastName">
                    <el-input :disabled="Edit" class="inline-input" clearable v-model="ruleForm.LastName"
                    placeholder="Last Name">
                    <span slot="prefix"  v-if="!ruleForm.LastName"   class="prefixRed">*</span></el-input>
                    </el-form-item>
                  </div>
            
                </div>
            </el-form>
          </div>
        </div>
        <div class="accountright_cont_box mt10 account_input">
          <div class="flex_center_between_box spec_title" style="">
            <h2 class="history_box-head">Invoices Downloaded</h2>
            <div style="display: flex;">
              <button class="flex_center todo_btn_mr10" :class="Dowload.length==0?'btn_disabled':'btn_blue'" :disabled="Dowload.length==0" style="margin-right:10px">
               <span v-if="Dowload.length==0"> Trading Issues</span>
                <a v-else style="color: #fff;" :href="'mailto:service@csibizinfo.com?subject=information need to correct&body=Customer service, hello: %0D%0Aand If you have any questions about the selected single invoice or multiple invoices, please %0D%0Aand enter your questions below'+Dowload[0]?.email+Dowload[0]?.createTime+Dowload[0]?.paymentAmount+'%0D%0Aand Please help to handle it as soon as possible. thank you' ">
              Trading Issues
            </a>
              </button>
              <button class="flex_center todo_btn" @click="getInvoicePdfAddress()" :class="Dowload.length==0?'btn_disabled':'btn_blue'" :disabled="Dowload.length==0">
                Download Invoice
              </button>
            </div>
          </div>
          <div class="his_sel_box common_checkbox" style="width: 100%">
            <el-table class="spec_table table_height_30 table_font_size_14" :data="yourUsagelist" style="width: 100%" @selection-change="handleSelectionChange" empty-text="No Data">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="createTime" label="Date" align="center">
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="paymentAmount" label="User Account" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.email }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="paymentAmount" label="Payment Amount" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="table-content " style="">
                    {{ scope.row.paymentAmount }}
                  </div>
                </template>
              </el-table-column>

            </el-table>
            <div class="show_AllHeight" v-show="this.page.total>this.page.pageSize"  style="text-align: center; padding-top: 20px" v-if="yourUsagelist.length!=0">
              <span class="contclass show_All" @click="apptablelist">Show All</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";
import { getInvoiceInformation,saveInvoiceInformation,updateInvoiceInformation,getInvoicePdfAddress,getUserRechargesPage} from "@/api/userApi";
import ReLogin from "../../../components/ReLogin.vue";

export default {
  mixins: [mixins1],
  components: {
    ReLogin
  },
  data() {
    return {
      reLoginVis: false,
      value1: "",
      CategoryVal: "",
      ruleForm: {
        CompanyName: '',
        PhoneNumber: '',
        CompanyID: '',
        Email: '',
        Region: '',
        BankAccountHolder: '',
        AddressLine : '',
        AddressLine2: '',
        CODE:'',
        City:'',
        BankName:'',
        AccountNumber:'',
        ZipCode:'',
        BeneficiaryBankAddress:'',
        FirstName:'',
        ABA:'',
        LastName:'',
        countryCode:'',
        userId:'',
        },
        rules: {
          CompanyName: [
            { required: true, message: 'CompanyName', trigger: 'blur' },
          ],
     
          PhoneNumber: [
            { required: true, message: '*Company ID', trigger: 'blur' },
          ],
          Email: [
            { required: true, message: 'Email', trigger: 'blur' },
          ],
          Region: [
            { required: true, message: '*Country/Region', trigger: 'blur' },
          ],
          BankAccountHolder: [
            { required: true, message: '*Bank Account Holder Name', trigger: 'blur' },
          ],
          BankName: [
            { required: true, message: '*Beneficiary Bank Name', trigger: 'blur' },
          ],
          AddressLine: [
            { required: true, message: '*Address Line 1', trigger: 'blur' },
          ],
          AddressLine2: [
            { required: true, message: 'Address Line 2', trigger: 'blur' },
          ],
          CODE: [
            { required: true, message: '*SWIFT CODE(BIC)', trigger: 'blur' },
          ],
          City: [
            { required: true, message: '*City', trigger: 'blur' },
          ],
          AccountNumber: [
            { required: true, message: '*Account Number', trigger: 'blur' },
          ],
          ZipCode: [
            { required: true, message: '*Zip Code', trigger: 'blur' },
          ],
          BeneficiaryBankAddress: [
            { required: true, message: '*Beneficiary Bank Address', trigger: 'blur' },
          ],
          FirstName: [
            { required: true, message: '*First Name', trigger: 'blur' },
          ],
          ABA: [
            { required: true, message: '*ABA(Routing Number)', trigger: 'blur' },
          ],
          LastName: [
            { required: true, message: '*LastName', trigger: 'blur' },
          ],
          LastName: [
            { required: true, message: '*LastName', trigger: 'blur' },
          ],
 
     
          // date1: [
          //   { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          // ],
   
        },
      yourUsagelist: [],
      window: window,
      Edit: true,
      page: {
        pageSize: 10,
        pageNum: 1,
        total:0
      },
      Dowload:[]
    };
    
  },
  beforeDestroy() { },
  destroyed() { },
  mounted() { 
    this.getInvoiceInformationList()
    this.getgetUserActionLogPage();
  },

  methods: {
    getgetUserActionLogPage() {
       getUserRechargesPage({
        // email: this.CategoryVal,
        // actualAmount: this.CategoryVal1,
        // startTime: this.datePicker == null ? "" : this.datePicker[0],
        // endTime: this.datePicker == null ? "" : this.datePicker[1],
        hasUserInvoiceId:false,
        page: this.page.pageNum,
        pageSize:this.page.pageSize,
      }).then((res) => {
        
        if (res.code == 200) {
          this.yourUsagelist = res.data.rows;
          this.page.total=res.data.total
        } else {
          this.yourUsagelist = [];
        }
      });

    },
    // 发票信息
    getInvoiceInformationList(){
      getInvoiceInformation().then(res=>{
        if(res.code==200){
          this.ruleForm= {
            CompanyName: res.data.companyName,
        PhoneNumber: res.data.phoneNumber,
        CompanyID: res.data.companyId,
        Email: res.data.email,
        Region: res.data.countryRegion,
        BankAccountHolder: res.data.bankHolderName,
        AddressLine : res.data.addressLine1,
        AddressLine2: res.data.addressLine2,
        CODE:res.data.swiftCode,
        City:res.data.city,
        BankName:res.data.beneficiaryBankName,
        AccountNumber:res.data.accountNumber,
        ZipCode:res.data.zipCode,
        BeneficiaryBankAddress:res.data.beneficiaryBankAddress,
        FirstName:res.data.firstName,
        ABA:res.data.aba,
        LastName:res.data.lastName,
        userId:res.data.userId,
        countryCode:'US'
        }
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    SubmitFrom(formName){
      this.$refs[formName].validate((valid) => {
          if (valid) {
            let submit ={
                userId:this.ruleForm.userId,
                companyName:this.ruleForm.CompanyName,
                companyId:this.ruleForm.CompanyID,
                countryRegion:this.ruleForm.Region,
                addressLine1:this.ruleForm.AddressLine,
                addressLine2:this.ruleForm.AddressLine2,
                city:this.ruleForm.City,
                zipCode:this.ruleForm.ZipCode,
                firstName:this.ruleForm.FirstName,
                lastName:this.ruleForm.LastName,
                phoneNumber:this.ruleForm.PhoneNumber,
                countryCode:this.ruleForm.countryCode,
                email:this.ruleForm.Email,
                bankHolderName:this.ruleForm.BankAccountHolder,
                beneficiaryBankName:this.ruleForm.BankName,
                swiftCode:this.ruleForm.CODE,
                accountNumber:this.ruleForm.AccountNumber,
                beneficiaryBankAddress:this.ruleForm.BeneficiaryBankAddress,
                aba:this.ruleForm.ABA,
                
              }
            if(this.ruleForm.userId){
              updateInvoiceInformation(submit).then(res=>{
                if(res.code==200){
                  this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.Edit=true
                }
                
              })

            }else{
              saveInvoiceInformation(submit).then(res=>{
                if(res.code==200){
                  this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.Edit=true
                }
                
              })
              
            }
            // alert('submit!');
            
          } else {
            return false;
          }
        });
      // Edit = !Edit
    },
    handleSelectionChange(val) {
      this.Dowload=val
      
    },
    // 下载发票
    getInvoicePdfAddress(){
      let userInvoiceIdArr=[]
      this.Dowload.forEach((item,index)=>{
          userInvoiceIdArr.push(item.id)
        
      })
    
      getInvoicePdfAddress({ids:userInvoiceIdArr.join(',')}).then(res=>{
        if(res.code==200){
          window.open(res.data, '_blank');
          window.location.reload();
        }else{
          
          this.$message.error(res.msg);
        }
        
      })
      
    },
    apptablelist() {
      this.page.pageSize=  this.page.pageSize+5

   this.getgetUserActionLogPage()
    }
  },
};
</script>
<style scoped>
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.form-email {
  display: flex;
  width: 100%;
}

.block-con-body-text {
  word-break: keep-all;
  text-align: justify;
  font-size: 1rem;
  color: #022955;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.item_txtcolor1 {
  color: #1290c9;
}

.item_txtcolor2 {
  color: #55b419;
}

.item_txtcolor3 {
  color: #ffa000;
}

.das_top_item .color1 {
  background: rgba(18, 144, 201, 0.12);
}

.das_top_item .color2 {
  background: rgba(85, 180, 25, 0.12);
}

.das_top_item .color3 {
  background: rgba(255, 160, 0, 0.12);
}

.das_top_item .color4 {
  background: #ff7600;
}

.das_top_item .item_img img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: left;
}

.das_top_item .item_group {
  margin-left: 0.7142857142857143rem;
  font-size: 1.2857142857142858rem;

  /* width: 17.142857142857142rem; */
}

.das_top_item .item_lab {
  display: block;
  color: #1a2332;

  margin-top: 0.35714285714285715rem;
}

.item_txt {
  font-size: 2.142857142857143rem;
  text-align: center;
}

.cont_m {
  margin-top: 2.142857142857143rem;
  margin-bottom: 5.714285714285714rem;
}

.country_box {
  margin-top: 4.285714285714286rem;
}

.country_box .title {
  margin-bottom: 0;
}

.title {
  margin-bottom: 1.4285714285714286rem;
}

.title_txt {
  font-size: 1.1428571428571428rem;
  font-family: 'DMSans Bold';
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.c_d_buttom {
  justify-content: flex-end;
  /* margin-top: 1.4285714285714286rem; */
  margin-bottom: 0;
}

.btn_blue {
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #1290c9;
  font-size: 1.2857142857142858rem;
  color: #ffffff;
  cursor: pointer;
}

.btn_blu {
  margin-right: 1.4285714285714286rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}

.sel_box {
  margin-right: 1.7857142857142858rem;
  width: 35%;
  height: 2.857142857142857rem;
}

.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.4285714285714286rem;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.das_top_item {
  width: 32.5%;
  padding: 1.4285714285714286rem;
  /* border-radius: 1rem; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
}

.das_top_item .item_img {
  width: 4.285714285714286rem;
  height: 4.285714285714286rem;
  line-height: 5.571428571428571rem;
  text-align: center;
  border-radius: 50%;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: center;
}

.top_tab {
  margin-top: 2.857142857142857rem;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  /* line-height: 2.142857142857143rem; */
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: 'DMSans Bold';
}

.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}

.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}

/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0.07142857142857142rem dashed #d9d9d9;
  border-radius: 0.42857142857142855rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 1.4285714285714286rem;
  color: #8c939d;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
}

/deep/ .avatar {
  width: 5rem;
  height: 5rem;
  display: block;
}

/* 日历大小 */
.el-range-editor.el-input__inner {
  /* width: 16rem !important; */
  padding: 0.21428571428571427rem 0.21428571428571427rem;
}

.el-date-editor /deep/ .el-range__icon {
  font-size: 1.1428571428571428rem;
  margin-left: -0.35714285714285715rem;
  color: #1a2332;
  /* float: left; */
  line-height: 2.2857142857142856rem;
  position: relative;
  left: 47%;
}

.information-content {
  display: flex;
  margin-bottom: 1.1428571428571428rem;
  /* margin-bottom: 1.7857142857142858rem; */
}

.btn_eee {
  background: #eee;
  color: #1a2332;
}
/deep/ .prefixRed{
  color: red;
  margin-left: 0.7142857142857143rem;
}
/deep/ .el-input__inner {
    padding-left: 1.4285714285714286rem !important;
}

.account_input .spec_title {
  margin-bottom: 1.1428571428571428rem;
}
@media screen and (max-width: 1441px) {
  .btn_blue {
    font-size: 14px;
    width: max-content;
  }
}
</style>